import { Button, Icon } from '@sgme/ui';
import { Card, CardBody, CardHeader, CardTitle } from '@/components/shared/Card';
import { Checkbox } from '@/components/shared/Checkbox';
import { DealerPriceCell } from '@/components/DealersListTable/DealerPriceCell';
import { DealersSelectionBanner } from '@/components/DealersListTable/DealersSelectionBanner';
import { type FC, useCallback, useEffect, useMemo, useState } from 'react';
import { FormattedText } from '@/components/shared/FormattedText';
import { Table, Tbody, Td, Th, Thead, Tr } from '@/components/shared/Table';
import { WithTooltip } from '@/components/shared/WithTooltip';
import {
  auctionDealersRequested,
  derogationReasonsRequested,
  getBlotterAuctionByUuid,
  getPdcDerogationReasons,
  pdcDetailsRequested,
} from '@/store/auctions';
import { copyToClipboardHandler, triggerCopyToClipboard } from '@/utils/clipboard/clipboard';
import { getCurrentLocale } from '@/store/sessions';
import { getPdcStatusTextColor } from '@/utils/status/status-colors.utils';
import { getTranslations } from '@/locales';
import { hasPermission } from '@/store/users';
import { isEmpty } from '@sgme/fp';
import { openOverrideModal, openPdcDetailsModal } from '@/store/ui';
import { sortBy } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { useToggleFeature } from '@/hooks/useToggleFeature/useToggleFeature';
import type { Auction, ConfirmDealersRequest, RfqDealers, SalesCounterparty } from '@/models';
import type { IntlKey } from '@/locales';

export interface DealersListTableProps {
  className?: string;
  auction: Auction;
  shouldSelectAuctionWinner?: boolean;
}

export const DealersListTable: FC<DealersListTableProps> = ({
  className,
  auction: { workflowStatus, rfqDealers, auctionUuid, auctionBaseRfq, dealerSelected },
  shouldSelectAuctionWinner,
}) => {
  const locale = useSelector(getCurrentLocale);
  const translations = getTranslations(locale);
  const dispatch = useDispatch();

  const hasViewTechnicalIdPermission = useSelector(hasPermission('VIEW_TECHNICAL_ID'));

  const canCopyRfqId = useMemo(() => hasViewTechnicalIdPermission, [hasViewTechnicalIdPermission]);

  const onCopyRfqIdClicked = useCallback((rfqId?: string) => {
    if (isEmpty(rfqId)) {
      return;
    }
    triggerCopyToClipboard(copyToClipboardHandler(), rfqId, false);
  }, []);

  const isPdcDetailsOn = useToggleFeature('PdcDetails');
  const canSeePdcDetails = useSelector(hasPermission('READ_PDC_DETAILS'));
  const canSelectDealers = useSelector(hasPermission('SELECT_DEALERS_FOR_AUCTION'));
  const showReselection =
    canSelectDealers && (workflowStatus === 'AUCTION_AWAITING_TO_START' || workflowStatus === 'AUCTION_PDC_COMPLETED');

  const canOverrideQuoteDetails = useSelector(hasPermission('OVERRIDE_QUOTE_DETAILS'));
  const auction = useSelector(getBlotterAuctionByUuid(auctionUuid));
  const dealersToBeOverridden =
    auction?.auction?.rfqDealers?.filter(
      dealer => dealer.dealerRfqId !== undefined && dealer.sendForBidding === true,
    ) ?? [];
  const showOverride =
    dealersToBeOverridden.length > 0 &&
    canOverrideQuoteDetails &&
    (workflowStatus === 'AUCTION_ONGOING' || workflowStatus === 'AUCTION_CUTOFF_PASSED');

  const filteredDealers = useMemo(() => {
    const validDealers = rfqDealers?.filter(dealer => dealer.dealerRfqId !== undefined) ?? [];
    return sortBy(validDealers, o => o.salesCounterparty?.name);
  }, [rfqDealers]);

  const [selectedDealers, setSelectedDealers] = useState<RfqDealers[]>([]);

  const [isDealersSelected, setIsDealersSelected] = useState<boolean>(dealerSelected ?? false);

  const isIntermediate = useMemo(
    () => selectedDealers.length !== filteredDealers.length && selectedDealers.length !== 0,
    [filteredDealers, selectedDealers],
  );

  const isSelectedAll = useMemo(
    () => selectedDealers.length === filteredDealers.length,
    [filteredDealers, selectedDealers],
  );

  const isChecked = useCallback(
    (id: string) => {
      return selectedDealers.filter(dealer => dealer.dealerRfqId === id).length > 0;
    },
    [selectedDealers],
  );

  const onSelectAllCheckboxToggled = useCallback(() => {
    if (isSelectedAll) {
      setSelectedDealers([]);
    } else {
      setSelectedDealers(filteredDealers.map(dealer => dealer));
    }
  }, [filteredDealers, isSelectedAll]);

  const onCheckboxToggled = useCallback(
    (checked: boolean, id: string) => {
      const dealerToggled = filteredDealers.find(dealer => dealer.dealerRfqId === id);
      if (dealerToggled) {
        if (checked) {
          setSelectedDealers([...selectedDealers, dealerToggled]);
        } else {
          setSelectedDealers(selectedDealers.filter(dealer => dealer !== dealerToggled));
        }
      }
    },
    [selectedDealers, filteredDealers],
  );

  const isSaveSelectionBtnDisabled = useMemo(() => {
    return selectedDealers.length === 0;
  }, [selectedDealers]);

  const onSaveSelectionClicked = useCallback(() => {
    const selectedSalesCounterparties = selectedDealers.map(dealer => {
      const { id, eliotCode, mnemo, name } = dealer.salesCounterparty as SalesCounterparty;
      return { id, eliotCode, mnemo, name };
    });
    const request = { dealers: selectedSalesCounterparties } as ConfirmDealersRequest;
    if (auctionUuid) {
      dispatch(auctionDealersRequested(auctionUuid, request));
    }
    setIsDealersSelected(true);
  }, [auctionUuid, dispatch, selectedDealers]);

  const onReselectDealersClicked = useCallback(() => {
    setIsDealersSelected(false);
  }, []);

  useEffect(() => {
    setSelectedDealers(
      filteredDealers.flatMap(dealer => (dealer.sendForBidding && dealer.dealerRfqId !== undefined ? [dealer] : [])),
    );
  }, [filteredDealers]);

  const derogationReasons = useSelector(getPdcDerogationReasons());
  const onPdcDetailsIconClicked = useCallback(
    (dealerRfqId: string | undefined) => {
      if (dealerRfqId !== undefined) {
        dispatch(pdcDetailsRequested([dealerRfqId]));
        dispatch(openPdcDetailsModal(dealerRfqId));

        if (derogationReasons === undefined) {
          dispatch(derogationReasonsRequested());
        }
      }
    },
    [dispatch, derogationReasons],
  );

  return (
    <>
      <Card padding={3} className={className}>
        <CardHeader>
          <CardTitle size={6}>
            <FormattedText id="Dealers list" />
          </CardTitle>
        </CardHeader>
        <CardBody>
          {showReselection && (
            <DealersSelectionBanner
              selectedDealersCount={selectedDealers.length}
              onReselectDealersClicked={onReselectDealersClicked}
              onSaveSelectionClicked={onSaveSelectionClicked}
              isSaveSelectionBtnDisabled={isSaveSelectionBtnDisabled}
              isDealersSelected={isDealersSelected}
            />
          )}
          <Table className="w-100 table-sm m-0">
            <Thead>
              <Tr>
                {showReselection && (
                  <th style={{ width: 10 }} className="p-0">
                    <Checkbox
                      id={auctionUuid === null ? undefined : auctionUuid}
                      className="form-check-edited"
                      isIntermediate={isIntermediate}
                      checked={isSelectedAll}
                      onChange={onSelectAllCheckboxToggled}
                      disabled={isDealersSelected}
                    />
                  </th>
                )}
                <FormattedText as={Th} id="Dealers" />
                <Th>
                  <div className="d-flex justify-content-between">
                    <FormattedText id="PDC T-1 status" />
                  </div>
                </Th>
                <Th>
                  <div className="d-flex justify-content-between ">
                    <FormattedText id="PDC status" />
                  </div>
                </Th>
                <FormattedText as={Th} className="w-50" colSpan={auctionBaseRfq?.instruments?.length} id="Price" />
              </Tr>
              <Tr>
                {showReselection && <Td></Td>}
                <Td />
                <Td />
                <Td />
                {auctionBaseRfq?.instruments?.map(instrument => (
                  <Td key={instrument.instrumentId}>
                    <div className="d-flex justify-content-between fw-semibold">
                      <div>
                        <FormattedText id={`instrumentType.${instrument?.instrumentType}`} />
                        <span className="ms-1" />
                        <FormattedText text={instrument?.instrumentLegs?.[0]?.underlying?.bloombergCode} />
                      </div>
                      {showOverride && (
                        <button
                          type="button"
                          className="btn btn-outline-primary btn-sm"
                          onClick={() => {
                            const instrumentType = `instrumentType.${instrument?.instrumentType}` as IntlKey;
                            const overrideInstrument = `${translations[instrumentType] ?? '-'} ${instrument?.instrumentLegs?.[0]?.underlying?.bloombergCode ?? '-'}`;
                            dispatch(openOverrideModal(auctionUuid, overrideInstrument));
                          }}
                        >
                          Override
                        </button>
                      )}
                    </div>
                  </Td>
                ))}
              </Tr>
            </Thead>
            <Tbody>
              {filteredDealers.map(dealer => (
                <Tr key={dealer.dealerRfqId}>
                  {showReselection && (
                    <td style={{ width: 10 }} className="p-0">
                      <Checkbox
                        id={dealer.dealerRfqId!}
                        className="form-check-edited"
                        onChange={onCheckboxToggled}
                        checked={isChecked(dealer.dealerRfqId!)}
                        disabled={isDealersSelected}
                      />
                    </td>
                  )}
                  {canCopyRfqId ? (
                    <td>
                      <div className="d-flex align-items-center justify-content-between">
                        <span>{dealer.salesCounterparty?.name}</span>
                        <Button
                          purpose="flat"
                          color="secondary"
                          iconPosition="icon-only"
                          onClick={() => onCopyRfqIdClicked(dealer.dealerRfqId)}
                          size="sm"
                          className="ms-2"
                        >
                          <WithTooltip value="Copy RFQ ID" className="p-1">
                            <Icon icon="integration_instructions" outlined size="sm" />
                          </WithTooltip>
                        </Button>
                      </div>
                    </td>
                  ) : (
                    <FormattedText as={Td} text={dealer.salesCounterparty?.name} />
                  )}
                  <FormattedText
                    as={Td}
                    className={getPdcStatusTextColor(dealer.prePdcStatus)}
                    id={`pdcStatus.${dealer.prePdcStatus}`}
                  />
                  <td>
                    <div className="d-flex justify-content-between align-items-center">
                      <FormattedText
                        as="span"
                        className={getPdcStatusTextColor(dealer.pdcStatus)}
                        id={`pdcStatus.${dealer.pdcStatus}`}
                      />
                      {isPdcDetailsOn && canSeePdcDetails && (
                        <Button
                          purpose="flat"
                          color="secondary"
                          iconPosition="icon-only"
                          onClick={() => onPdcDetailsIconClicked(dealer.dealerRfqId)}
                          size="sm"
                          className="ms-2"
                        >
                          <Icon icon="info" outlined size="sm" />
                        </Button>
                      )}
                    </div>
                  </td>
                  <DealerPriceCell
                    shouldSelectAuctionWinner={shouldSelectAuctionWinner}
                    dealer={dealer}
                    auctionBaseRfq={auctionBaseRfq}
                    showPriceAndUnitOnly={false}
                    displayInCell={true}
                    highlightCell={dealer.executedQuote}
                  />
                </Tr>
              ))}
            </Tbody>
          </Table>
        </CardBody>
      </Card>
    </>
  );
};
