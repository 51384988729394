import { Modal } from '@/components/shared/Modal';
import { closePdcDetailsModal, getPdcModalIsDerogationOpened, getPdcModalRfqId, openDerogation } from '@/store/ui';
import {
  computePdcStatusForSalesCounterparty,
  computePdcStatusForStrategy,
  getDerogationReasonsByType,
  pdcTypes,
} from '@/helper/pdc';
import { getPdcDerogationReasons, getPdcDetails } from '@/store/auctions';
import { hasPermission } from '@/store/users';
import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useToggleFeature } from '@/hooks/useToggleFeature/useToggleFeature';
import PdcDerogationCard from '@/components/Modal/Pdc/PdcDerogationCard';
import PdcStatusIcon from '@/components/Modal/Pdc/PdcStatusIcon';
import PdcTableSkeleton from '@/components/Modal/Pdc/PdcTableSkeleton';
import classnames from 'classnames';
import type { ModalProps } from '@/components/shared/Modal';
import type { OptionItem } from '@/models';
import type { PdcType, PdcTypeEnum } from '@/helper/pdc';

interface PdcDetailsModalProps extends ModalProps {
  initialDisplayed?: boolean;
}

const PdcDetailsModal: React.FC<PdcDetailsModalProps> = ({ initialDisplayed }) => {
  const dispatch = useDispatch();

  const pdcDerogationEnabled = useToggleFeature('PdcDerogation');

  const rfqId = useSelector(getPdcModalRfqId());
  const pdcDetails = useSelector(getPdcDetails(rfqId));
  const haveDerogatePermission = useSelector(hasPermission('PERFORM_PDC_DEROGATION'));
  const derogationReasons = useSelector(getPdcDerogationReasons());
  const isDerogationOpened = useSelector(getPdcModalIsDerogationOpened());

  const [derogationReasonOptionItems, setDerogationReasonOptionItems] = useState<OptionItem[]>([]);
  const [fullPdcDetailsUrl, setFullPdcDetailsUrl] = useState<string | undefined>(undefined);
  const [strategyIds, setStrategyIds] = useState<(string | undefined)[]>([]);
  const [existingPdcTypes, setExistingPdcTypes] = useState<PdcType[]>(pdcTypes);

  useEffect(() => {
    setStrategyIds(
      (
        pdcDetails?.transaction?.pdcs
          ?.map(pdc => pdc.strategyUuid)
          .filter((value, index, self) => self.indexOf(value) === index) ?? []
      ).filter(id => id !== undefined && id !== ''),
    );
    setFullPdcDetailsUrl(pdcDetails?.transaction?.pdcs?.find(pdc => pdc.type === 'CREDIT_CHECK_ASK')?.url);
    const allExistingPdcTypes = pdcDetails?.transaction?.pdcs
      ?.filter(pdc => pdc.level === 'ALLOCATION')
      ?.map(pdc => pdc.type);
    setExistingPdcTypes(pdcTypes.filter(type => allExistingPdcTypes?.includes(type.id)));
  }, [pdcDetails]);

  const onConfirm = useCallback(() => {
    if (fullPdcDetailsUrl !== undefined) {
      window.open(fullPdcDetailsUrl);
    }
  }, [fullPdcDetailsUrl]);

  const onPdcStatusClicked = useCallback(
    (
      legIndex: number,
      typeId: PdcTypeEnum,
      typeLabel: string | undefined | null,
      isEligible: boolean | undefined = false,
      pdcUuid: string | undefined,
      message: string | undefined,
      derogationReason: string | undefined,
    ) => {
      if (derogationReasons !== undefined && typeId !== null && typeId !== undefined) {
        const options =
          getDerogationReasonsByType(derogationReasons, typeId)?.map(reason => {
            return {
              value: reason.type,
              label: reason.message,
              translationKey: undefined,
            } as OptionItem;
          }) ?? [];
        setDerogationReasonOptionItems(options);
      }
      dispatch(
        openDerogation({
          legIndex: legIndex,
          typeId: typeId ?? '',
          typeLabel: typeLabel ?? '',
          isEligible: isEligible ?? false,
          pdcUuid: pdcUuid ?? '',
          message: message,
          derogationReason: derogationReason,
        }),
      );
    },
    [derogationReasons, dispatch],
  );

  const borderRightStyle = '1px lightGrey solid';

  return (
    <Modal
      initialDisplayed={initialDisplayed}
      id="pdc-details-modal"
      title={`Pre-Deal check (PDC) status`}
      minWidth={800}
      color={'primary'}
      children={
        <>
          {pdcDetails === undefined ? (
            <div data-e2e="loading-skeleton" className={`w-100 flex-fill mb-4`}>
              <PdcTableSkeleton borderRightStyle={borderRightStyle} />
            </div>
          ) : (
            <table className="w-100 table-sm">
              <thead>
                <tr className="border-bottom">
                  <th></th>
                  <th className="fw-semibold text-center" style={{ maxWidth: '150px', borderRight: borderRightStyle }}>
                    {pdcDetails?.transaction?.rfq?.salesCounterparty?.name}
                  </th>
                  {strategyIds.map((strategyId, strategyIndex) => {
                    const legIds = (
                      pdcDetails?.transaction?.pdcs
                        ?.filter(pdc => pdc.strategyUuid === strategyId)
                        ?.map(pdc => pdc.legUuid)
                        .filter((value, index, self) => self.indexOf(value) === index) ?? []
                    ).filter(id => id !== undefined && id !== '');
                    return (
                      <>
                        <th className="fw-semibold">
                          <div className="d-flex justify-content-center">{`Strategy ${strategyIndex + 1}`}</div>
                        </th>
                        {legIds.map((legId, legIndex) => (
                          <th
                            key={legId}
                            className="fw-semibold"
                            style={{
                              borderRight:
                                legIndex === legIds.length - 1 && strategyIndex !== strategyIds.length - 1
                                  ? borderRightStyle
                                  : '',
                            }}
                          >
                            <div className="d-flex justify-content-center">{`Leg ${legIndex + 1}`}</div>
                          </th>
                        ))}
                      </>
                    );
                  })}
                </tr>
              </thead>
              <tbody>
                {existingPdcTypes.map((type, typeIndex) => (
                  <tr
                    key={type.id}
                    className={classnames({ 'border-bottom': typeIndex !== existingPdcTypes.length - 1 })}
                  >
                    <td>
                      <div>{type.label}</div>
                    </td>
                    <td style={{ borderRight: borderRightStyle }}>
                      <div className="d-flex justify-content-center">
                        <PdcStatusIcon
                          uiColor={computePdcStatusForSalesCounterparty(
                            type,
                            pdcDetails?.transaction?.pdcs,
                            strategyIds,
                          )}
                        />
                      </div>
                    </td>
                    {strategyIds.map((strategyId, strategyIndex) => {
                      const pdcsOfStrategy = pdcDetails?.transaction?.pdcs?.filter(
                        pdc => pdc.strategyUuid === strategyId && pdc.level === 'ALLOCATION',
                      );
                      const legIds = (
                        pdcsOfStrategy
                          ?.map(pdc => pdc.legUuid)
                          .filter((value, index, self) => self.indexOf(value) === index) ?? []
                      ).filter(id => id !== undefined && id !== '');
                      return (
                        <>
                          <td>
                            <div className="d-flex justify-content-center">
                              <PdcStatusIcon uiColor={computePdcStatusForStrategy(type, pdcsOfStrategy)} />
                            </div>
                          </td>
                          {legIds.map((value, legIndex) => {
                            const pdc = pdcsOfStrategy?.find(pdc => pdc.type === type.id && pdc.legUuid === value);
                            return (
                              <td
                                key={value}
                                style={{
                                  borderRight:
                                    legIndex === legIds.length - 1 && strategyIndex !== strategyIds.length - 1
                                      ? borderRightStyle
                                      : '',
                                }}
                              >
                                <div className="d-flex justify-content-center">
                                  {pdc?.isDerogable === true &&
                                  haveDerogatePermission &&
                                  pdcDerogationEnabled &&
                                  !isDerogationOpened ? (
                                    <button
                                      className="btn btn-flat-secondary btn-sm visible"
                                      onClick={() =>
                                        onPdcStatusClicked(
                                          legIndex + 1,
                                          type.id,
                                          type.label,
                                          pdc?.eligible,
                                          pdc?.uuid,
                                          pdc.message,
                                          pdc.pdcDerogation,
                                        )
                                      }
                                    >
                                      <PdcStatusIcon uiColor={pdc?.uiColor} />
                                    </button>
                                  ) : (
                                    <PdcStatusIcon uiColor={pdc?.uiColor} />
                                  )}
                                </div>
                              </td>
                            );
                          })}
                        </>
                      );
                    })}
                  </tr>
                ))}
              </tbody>
            </table>
          )}
        </>
      }
      bottomCard={<PdcDerogationCard rfqId={rfqId} derogationReasonOptionItems={derogationReasonOptionItems} />}
      onClose={() => dispatch(closePdcDetailsModal())}
      onCancel={() => dispatch(closePdcDetailsModal())}
      cancelActionLabelKey="Dismiss"
      confirmActionHidden={fullPdcDetailsUrl === undefined}
      confirmActionLabelKey={'See full PDC status'}
      onConfirm={() => onConfirm()}
      confirmationPurpose="default"
      confirmationIcon="open_in_new"
      loading={false}
    />
  );
};

export default PdcDetailsModal;
